import React, { useEffect, useState } from 'react'
import '../css/footer.css'
import { NavLink, useLocation } from 'react-router-dom'
const Footer = () => {
    const [activeLink, setActiveLink] = useState('home'); // Initial active link
    const location = useLocation();
    useEffect(() => {
        // Update activeLink when the location changes
        //   setActiveLink(location.pathname.replace('/', ''));
        const currentPath = location.pathname.replace('/', '');
        setActiveLink(currentPath === '' ? 'home' : currentPath);
    }, [location]);
    const handleLinkClick = (link) => {
        localStorage.setItem("menu", link)
        setActiveLink(link);
        // You can add additional logic here if needed
    };
    const activeClass = (route) => {
        const currentPath = location.pathname.replace('/', '');

        if (currentPath === '' && (route === 'home' || route === '')) {
            return 'col active';
        }

        return currentPath === route ? 'col active' : 'col';
    };
    return (
        <>
            <div className="bottom-spacer" style={{ height: "65px" }}></div>
            <div className="bottom-nav">
                <div className="bottom-nav-bg row gx-0 box-wrapper mx-auto container-fluid d-flex align-content-center">
                    <div className={activeClass('home')}>
                        <div className="nav-box mt-2">
                            <NavLink to="/home" exact="true" className="nav-box-ico" onClick={() => handleLinkClick('home')}>
                                {
                                    activeLink === 'home' ?
                                        <svg width="99" height="50" viewBox="0 0 99 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M58.5 36.2501H41C40.3096 36.2501 39.75 35.6905 39.75 35.0001V23.7501H36L48.9091 12.0145C49.3859 11.5811 50.1141 11.5811 50.5909 12.0145L63.5 23.7501H59.75V35.0001C59.75 35.6905 59.1904 36.2501 58.5 36.2501ZM42.25 33.7501H57.25V21.447L49.75 14.6288L42.25 21.447V33.7501Z" fill="#2871FB" />
                                        </svg>
                                        :
                                        <svg width="99" height="50" viewBox="0 0 99 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M58.5 36.2501H41C40.3096 36.2501 39.75 35.6905 39.75 35.0001V23.7501H36L48.9091 12.0145C49.3859 11.5811 50.1141 11.5811 50.5909 12.0145L63.5 23.7501H59.75V35.0001C59.75 35.6905 59.1904 36.2501 58.5 36.2501ZM42.25 33.7501H57.25V21.447L49.75 14.6288L42.25 21.447V33.7501Z" fill="#969696" />
                                        </svg>

                                }
                            </NavLink>
                        </div>
                    </div>
                    <div className={activeClass('more-games')}>
                        <div className="nav-box mt-2">
                            <NavLink to="/more-games" exact="true" className="nav-box-ico" onClick={() => handleLinkClick('more-games')}>
                                {
                                    activeLink === 'more-games' ?
                                        <svg width="99" height="50" viewBox="0 0 99 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M51 36.25V23.75H61V36.25H51ZM38.5 26.25V13.75H48.5V26.25H38.5ZM46 23.75V16.25H41V23.75H46ZM38.5 36.25V28.75H48.5V36.25H38.5ZM41 33.75H46V31.25H41V33.75ZM53.5 33.75H58.5V26.25H53.5V33.75ZM51 13.75H61V21.25H51V13.75ZM53.5 16.25V18.75H58.5V16.25H53.5Z" fill="#2871FB" />
                                        </svg>
                                        :
                                        <svg width="99" height="50" viewBox="0 0 99 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M51 36.25V23.75H61V36.25H51ZM38.5 26.25V13.75H48.5V26.25H38.5ZM46 23.75V16.25H41V23.75H46ZM38.5 36.25V28.75H48.5V36.25H38.5ZM41 33.75H46V31.25H41V33.75ZM53.5 33.75H58.5V26.25H53.5V33.75ZM51 13.75H61V21.25H51V13.75ZM53.5 16.25V18.75H58.5V16.25H53.5Z" fill="#969696" />
                                        </svg>

                                }
                            </NavLink>
                        </div>
                    </div>
                    <div className={activeClass('leaderboard')}>
                        <div className="nav-box mt-2">
                            <NavLink to="/leaderboard" exact="true" className="nav-box-ico" onClick={() => handleLinkClick('leaderboard')}>
                                {
                                    activeLink === 'leaderboard' ?
                                        <svg width="99" height="50" viewBox="0 0 99 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M50.2677 16.25H61C61.6904 16.25 62.25 16.8096 62.25 17.5V35C62.25 35.6904 61.6904 36.25 61 36.25H38.5C37.8097 36.25 37.25 35.6904 37.25 35V15C37.25 14.3097 37.8097 13.75 38.5 13.75H47.7677L50.2677 16.25ZM39.75 16.25V33.75H59.75V18.75H49.2323L46.7322 16.25H39.75ZM48.5 21.25H51V31.25H48.5V21.25ZM53.5 25H56V31.25H53.5V25ZM43.5 27.5H46V31.25H43.5V27.5Z" fill="#2871FB" />
                                        </svg>
                                        :
                                        <svg width="99" height="50" viewBox="0 0 99 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M50.2677 16.25H61C61.6904 16.25 62.25 16.8096 62.25 17.5V35C62.25 35.6904 61.6904 36.25 61 36.25H38.5C37.8097 36.25 37.25 35.6904 37.25 35V15C37.25 14.3097 37.8097 13.75 38.5 13.75H47.7677L50.2677 16.25ZM39.75 16.25V33.75H59.75V18.75H49.2323L46.7322 16.25H39.75ZM48.5 21.25H51V31.25H48.5V21.25ZM53.5 25H56V31.25H53.5V25ZM43.5 27.5H46V31.25H43.5V27.5Z" fill="#969696" />
                                        </svg>

                                }
                            </NavLink>
                        </div>
                    </div>
                    <div className={activeClass('profile')}>
                        <div className="nav-box mt-2">
                            <NavLink to="/profile" className="nav-box-ico" onClick={() => handleLinkClick('profile')}>
                                {
                                    activeLink === 'profile' ?
                                        <svg width="99" height="50" viewBox="0 0 99 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M59.75 37.5H57.25V35C57.25 32.9289 55.5711 31.25 53.5 31.25H46C43.9289 31.25 42.25 32.9289 42.25 35V37.5H39.75V35C39.75 31.5483 42.5482 28.75 46 28.75H53.5C56.9517 28.75 59.75 31.5483 59.75 35V37.5ZM49.75 26.25C45.6079 26.25 42.25 22.8921 42.25 18.75C42.25 14.6079 45.6079 11.25 49.75 11.25C53.8921 11.25 57.25 14.6079 57.25 18.75C57.25 22.8921 53.8921 26.25 49.75 26.25ZM49.75 23.75C52.5114 23.75 54.75 21.5114 54.75 18.75C54.75 15.9886 52.5114 13.75 49.75 13.75C46.9886 13.75 44.75 15.9886 44.75 18.75C44.75 21.5114 46.9886 23.75 49.75 23.75Z" fill="#2871FB" />
                                        </svg>
                                        :
                                        <svg width="99" height="50" viewBox="0 0 99 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M59.75 37.5H57.25V35C57.25 32.9289 55.5711 31.25 53.5 31.25H46C43.9289 31.25 42.25 32.9289 42.25 35V37.5H39.75V35C39.75 31.5483 42.5482 28.75 46 28.75H53.5C56.9517 28.75 59.75 31.5483 59.75 35V37.5ZM49.75 26.25C45.6079 26.25 42.25 22.8921 42.25 18.75C42.25 14.6079 45.6079 11.25 49.75 11.25C53.8921 11.25 57.25 14.6079 57.25 18.75C57.25 22.8921 53.8921 26.25 49.75 26.25ZM49.75 23.75C52.5114 23.75 54.75 21.5114 54.75 18.75C54.75 15.9886 52.5114 13.75 49.75 13.75C46.9886 13.75 44.75 15.9886 44.75 18.75C44.75 21.5114 46.9886 23.75 49.75 23.75Z" fill="#969696" />
                                        </svg>

                                }
                            </NavLink>

                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Footer