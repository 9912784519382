import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from "react-router-dom";

import Error from "utils/Error";
import AuthGuard from "utils/AuthGuard";
import FindingOpponent from "components/findingOpponent";
import LeaderBoard from "components/leaderBoard";
import GameDetails from "components/gameDetails";
import AllQuizes from "components/allQuizes";
import Screen3 from "components/screen3";
import Screen2 from "components/screen2";
import Layouts from "components/layouts/layout";
import Profile from "components/profile";
import PlayGames from "components/play";
import LossGames from "components/loss";
import History from "components/history";
import EditProfile from "components/editProfile";
import Policy from "components/policy";
import ModalPlay from "components/modelPlay";
import WinGames from "components/win";
import Language from "components/language";
import Contactus from "components/contactUs";
import Register from "components/login/register";
import BalanceGuard from "utils/BalanceGuard";

export const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route errorElement={<Error />}>
                <Route element={<AuthGuard isProtected={false} />}>
                    <Route path="/register" element={<Register />} />
                </Route>
                <Route element={<AuthGuard isProtected />}>
                    <Route element={<Layouts />}>
                        <Route path="/" element={<Screen2 />} />
                        <Route path="/home" element={<Screen2 />} />
                        <Route path="/more-games" element={<AllQuizes />} />
                        <Route path="/leaderboard" element={<LeaderBoard />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/history" element={<History />} />
                        <Route path="/edit" element={<EditProfile />} />
                        <Route path="/policy" element={<Policy />} />
                        <Route path="/language" element={<Language />} />
                        <Route path="/contact-us" element={<Contactus />} />
                        <Route element={<BalanceGuard />}>
                            <Route path="/levels" element={<Screen3 />} />
                            <Route path="/game-details" element={<GameDetails />} />
                            <Route path="/finding" element={<FindingOpponent />} />
                            <Route path="/play" element={<PlayGames />} />
                            <Route path="/loss" element={<LossGames />} />
                            <Route path="/modal" element={<ModalPlay />} />
                            <Route path="/won" element={<WinGames />} />
                        </Route>
                        <Route path="*" element={<Navigate to="/register" />} />
                    </Route>
                </Route>
            </Route>
        </>
    )
);
