import React, { useEffect, useState } from 'react'
import './css/screen3.css';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { getAllOpponent } from '../services/home';
import { useTranslation } from 'react-i18next';
import { getProfileImage } from 'utils/app.util';

const FindingOpponent = () => {
    const [opponent, setOpponent] = useState();
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        getAllOpponent().then((res) => {
            console.log(res)
            setOpponent(res)
            location.state.players = res
        }).catch((err) => {
            console.log(err)
        })
    }, []);

    return (
        <div className='home'>
            <div className="main-div">
                <div className="container-fluid box-wrapper difficult">
                    <div className="page-header pb-5">
                        <div className="box-wrapper mx-auto text-center position-relative header-container">
                            <button type="button" className="icon-button" onClick={() => navigate(-1)}>
                                <img src="/image/back.png" className='arrow' alt="back" />
                            </button>
                            <span className="levels-title">{t('25')}</span>
                        </div>
                    </div>
                    <div className="card-simple">
                        <div className='card-row'>
                            <div className="row ">
                                <div className="col-4">
                                    <div className="opponent">
                                        <div className="opponent-avatar">
                                            <img src={getProfileImage(opponent?.user?.image)} alt="avatar" />
                                        </div>
                                        <p className="m-0 name-title">{t('26')}</p>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="opponent2">
                                        <div className="opponent-avatar2">
                                            <img src="/image/opponent.png" alt="avatar" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="opponent" id="opponentFinding" style={{ display: "none" }}>
                                        <div className="opponent-avatar">
                                            <div className="opponent-avatar-loading">
                                                <img src="https://demo.quizpro.mobi//theme/images/finding.gif" alt="Loading" />
                                            </div>
                                        </div>
                                        <p className="m-0 text-muted">{t('27')}...</p>
                                    </div>
                                    <div className="opponent" id="opponentFound">
                                        <div className="opponent-avatar">
                                            <img src={getProfileImage(opponent?.opponent?.image)} alt="avatar" />
                                        </div>
                                        <p className="m-0 name-title">{opponent?.opponent?.name}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p className="my-3 text-center opponentMsg" id="opponentMsg" style={{ visibility: "visible" }}>{t('28')}!</p>
                        <NavLink to="/play" className="btn btn-lg button-primary w-100" id="btnGamePlay" state={location?.state}>{t('29')}</NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FindingOpponent