import React, { useState, useEffect, useMemo } from 'react'
import './css/style.css';
import Layouts from './layouts/layout';
import { NavLink } from 'react-router-dom';
import { getAllQuiz } from '../services/home';
import { useTranslation } from 'react-i18next';
import { DEFAULT_AVATAR_SRC } from '../utils/constants';
import { useSession } from 'providers/SessionProvider';

const Screen2 = () => {
  const [featured, setFeatured] = useState([]);
  const [popular, setPopular] = useState([]);
  const { user: profile } = useSession();
  const language = useMemo(() => localStorage.getItem('selectedLanguage') || 0, []);
  const { t, i18n } = useTranslation();


  useEffect(() => {
    getAllQuiz(language).then((res) => {
      const featuredArray = [];
      const popularArray = [];

      res.forEach((quiz) => {
        if (quiz.isFeatured) {
          featuredArray.push(quiz);
        }
        if (quiz.isPopular) {
          popularArray.push(quiz);
        }
      });

      setFeatured(featuredArray);
      setPopular(popularArray);
    }).catch((err) => {
      console.log(err)
    })
  }, [language])

  useEffect(() => {
    i18n.changeLanguage(language);
    getAllQuiz(language).then((res) => {
      const featuredArray = [];
      const popularArray = [];

      res.forEach((quiz) => {
        if (quiz.isFeatured) {
          featuredArray.push(quiz);
        }
        if (quiz.isPopular) {
          popularArray.push(quiz);
        }
      });

      setFeatured(featuredArray);
      setPopular(popularArray);
    }).catch((err) => {
      console.log(err)
    })
  }, [language, i18n])

  return (
    <div className="home">
      <div className="main-div">
        <div className="container box-wrapper">
          <div className="header">

            <div className="row align-items-center">
              <div className="col-6 text-start">
                <div className="logo d-flex">
                  <a href="/">
                    <img src="/image/Logo_new.png" alt="Logo" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="home-profile">
            <div className="row gx-0">
              <div className="col-7 text-start">
                <div className="d-flex align-items-center">
                  <div className="avatar">
                    <img src={profile.image || DEFAULT_AVATAR_SRC} alt="Avatar" />
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <div className="home-profile-name">{t('1')}</div>
                    <div className="home-profile-status">
                      {profile ? profile.name || profile.mobileNumber : "Guest"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-5 text-end">
                <div className="h-100 d-flex align-items-center justify-content-end">
                  <div className="home-profile-points">
                    <span className="home-profile-points-badge">
                      {" "}
                      <img src="/image/crown.png" alt="Logo" />{" "}
                    </span>
                    <span className="points-text">
                      {profile?.totalPoints || 0} {t('17')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="featured-quiz pb-2">
            <h3 className="section-title mb-3">{t('2')}</h3>
            <div className="row gx-3">
              {popular.map((quiz) => (
                <div key={quiz.id} className="col-lg-2 col-md-3 col-3">
                  <NavLink to="/game-details" className="quiz-box" state={quiz}>
                    {quiz?.image ? (
                      <img src={quiz?.image} alt={quiz.name} />
                    ) : (
                      <img src="image/science.png" alt="Quiz" />
                    )}
                    <p className="quiz-box-title">{quiz.name}</p>
                  </NavLink>
                </div>
              ))}
            </div>
          </div>
          <div className="featured-quiz">
            <h3 className="section-title mb-3">{t('3')}</h3>
            <div className="row gx-3">
              {featured.map((quiz) => (
                <div key={quiz.id} className="col-lg-2 col-md-3 col-3">
                  <NavLink to="/game-details" className="quiz-box" state={quiz}>
                    {quiz?.image ? (
                      <img src={quiz?.image} alt={quiz.name} />
                    ) : (
                      <img src="image/science.png" alt="Quiz" />
                    )}
                    <p className="quiz-box-title">{quiz.name}</p>
                  </NavLink>
                </div>
              ))}
            </div>
            <NavLink
              to="/more-games"
              className="btn btn-lg button-primary w-100"
            >
              {t('4')}
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Screen2