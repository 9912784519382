import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import './css/play.css';

const checkContentType = async (url) => {
  try {
    const response = await fetch(url);
    const contentType = response.headers.get('content-type');

    if (contentType && contentType.startsWith('image/')) {
      return 'image';
    } else if (contentType && contentType.startsWith('video/')) {
      return 'video';
    } else {
      return 'unknown';
    }
  } catch (error) {
    console.error('Error checking content type:', error);
    return 'error';
  }
};
const MyComponent = ({ url, question }) => {
  const [contentType, setContentType] = useState(null);

  useEffect(() => {
    const fetchContentType = async () => {
      if (url) {
        const type = await checkContentType(url);
        setContentType(type);
      }
    };

    fetchContentType();
  }, [url]);

  const renderContent = () => {
    switch (contentType) {
      case 'image':
        return <div className="multiple-question multiple-question-image">
          {url !== '' ? <img src={url} alt="Image" /> : null}
          <div className="multiple-question-content">
            <p className='game-que'>{question}</p>
          </div>
        </div>

          ;
      case 'video':
        // return <video controls width="50" src={url} />;
        return <div className="multiple-question multiple-question-image">
          <video width="100%" loop="true" autoplay="autoplay" controls="">
            <source src={url} type="video/mp4" /></video>
          <div className="multiple-question-content">
            <p className='game-que'>{question}</p>
          </div>
        </div>;
      // case 'unknown':
      //   return <p>Unknown content type</p>;
      // case 'error':
      //   return <p>Error checking content type</p>;
      default:
        return <div className="multiple-question multiple-question-image">

          <div className="multiple-question-content">
            <p className='game-que'>{question}</p>
          </div>
        </div>;
    }
  };

  return <div>{renderContent()}</div>;
};
const Question = ({ questionData, onAnswer, totalque }) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(null);
  const [opponentAnswer, setOpponentAnswer] = useState(null);
  const [clickedOption, setClickedOption] = useState(null);
  const [status, setStatus] = useState(false)

  useEffect(() => {
    setClickedOption(null);
    setSelectedOption(null);

  }, [questionData]);

  const handleOptionClick = (option) => {
    setClickedOption(option);
    setSelectedOption(option);
    const isCorrect = option === questionData.correctAnswer;
    setStatus(isCorrect)
    simulateOpponentAnswer()
    onAnswer(isCorrect, opponentAnswer);
  };

  const simulateOpponentAnswer = () => {
    // Simulate opponent answering with a mix of correct and incorrect responses
    const isCorrect = Math.random() < 0.5; // 50% chance of being correct

    if (isCorrect) {
      setOpponentAnswer(true)
    } else {
      setOpponentAnswer(false);
    }
  };

  return (
    <div className="container box-wrapper">
      <div className="swipe loaded">
        <div className="swipe-action">
          <div className="swipe-action-circle love">
            <span className="qf-icon-thumbs-up"></span>
          </div>
          <div className="swipe-action-circle nope">
            <span className="qf-icon-thumbs-down"></span>
          </div>
        </div>

        <div id="swipe-counter" className="swipe-counter">
          <span id="current" className="count">{questionData?.qno}</span> {t('63')} <span id="maxQuestion">{totalque}</span>
        </div>
        <div className="multiple">
          <div id="multipleQuestionDisplay">
            <MyComponent url={questionData?.image} question={questionData?.question} />

          </div>
          <ul className="multiple-answer" id="multipleAnswerDisplay">
            {questionData.options && questionData.options.map((option, index) => {
              return (
                <li key={option.key} className={`multiple-answer_list choosenAnswer`} data-question_id="905">
                  <input type="radio" className="2051" name="answer" id={`question${index + 1}`} onClick={() => handleOptionClick(index + 1)} />
                  <label className={`2051 d-flex align-items-center ${clickedOption === index + 1 ? (selectedOption === questionData.correctAnswer ? 'correct' : 'wrong') : ''}`} htmlFor={`question${index + 1}`} >
                    <span className="bullet pe-2">{index + 1}. </span>
                    <div>{option.value}</div>
                  </label>
                </li>
              )
            })}
          </ul>
        </div>
        <input type="hidden" name="qno" id="qno" value="0" />
        <input type="hidden" name="countdown" id="countdown" value="10" />
        <input type="hidden" name="currentQuestion" id="currentQuestion" value="0" />
      </div>
    </div>
  );
};

export default Question;
