import api from "./interceptor";

export const loginApi = async (userData) => {
    try {
        const response = await api.post(`auth/adminLogin`, userData);
        return response.data.data.token;
    } catch (e) {
        throw e.response.data;
    }
};

export const sendOtp = async (data) => {
    try {
        const response = await api.post(`auth/sendOtp`, data);
        return response.data.message;
    } catch (e) {
        throw e.response.data;
    }
};

export const login = async (data) => {
    try {
        const response = await api.post(`auth/verifyOtp`, data);
        return response.data;
    } catch (e) {
        throw e.response.data;
    }
};

export const getPacks = async () => {
    try {
        const response = await api.get(`auth/vasGetPacks`);
        return response.data;
    } catch (e) {
        console.log(e.response.data);
    }
};

export const registerVasUserWithISE2 = async (data) => {
    try {
        const response = await api.post(`he`, data);
        return response.data;
    } catch (e) {
        console.log(e);
    }
};

export const authenticateWithISE2 = async (ise2Code) => {
    try {
        const response = await api.post(`he/ise2-login`, { ise2Code });

        return response.data;
    } catch (e) {
        console.log(e.message);
        return Promise.reject(e);
    }
};