import axios from "axios";
import { clearSessionData, getSessionData } from "./session.service";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
});

instance.interceptors.request.use(async config => {
    const accessToken = getSessionData("token");
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
});

instance.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            localStorage.clear();
            clearSessionData();
            window.location.href = "/register";
            return;
        }
        return Promise.reject(error);
    }
);

export default instance;
