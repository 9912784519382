import api from "./interceptor";
import { setSessionData } from "./session.service";

export const getAllQuiz = async currentLanguage => {
    try {
        const response = await api.get(`quiz?lang_id=${currentLanguage}`);
        return response.data.data;
    } catch (e) {
        console.log(e);
    }
};

export const getAllQuestion = async quizId => {
    try {
        const response = await api.get(`quiz/question/all/${quizId}`);
        return response.data.data;
    } catch (e) {
        console.log(e);
    }
};

export const getAllLevel = async () => {
    try {
        const response = await api.get(`level`);
        return response.data.data;
    } catch (e) {
        console.log(e);
    }
};
export const getAllOpponent = async () => {
    try {
        const response = await api.get(`quiz/findOpponent`);
        return response.data.data;
    } catch (e) {
        console.log(e);
    }
};

export const getLeaderboard = async () => {
    try {
        const response = await api.get(`quiz/leaderboard`);
        return response.data.data;
    } catch (e) {
        console.log(e);
    }
};
export const getQuiz = async (quizId, levelId, currentLanguage) => {
    try {
        const response = await api.get(`quiz/${quizId}/${levelId}?lang_id=${currentLanguage}`);

        setSessionData("quizToken", response.data.quizToken);

        return response.data.data;
    } catch (e) {
        console.log(e);
    }
};

export const getHistory = async currentLanguage => {
    try {
        const response = await api.get(`quiz/history?lang_id=${currentLanguage}`);
        return response.data.data;
    } catch (e) {
        console.log(e);
    }
};

export const getProfile = async () => {
    try {
        const response = await api.get(`auth/profile`);
        return response.data.data;
    } catch (e) {
        console.log(e);
    }
};
export const updateProfile = async formData => {
    try {
        const response = await api.patch(`auth/profile`, formData);
        return response.data.data;
    } catch (e) {
        console.log(e);
    }
};

export const updateScore = async (quizId, levelId, totalPoints) => {
    try {
        const response = await api.post(`quiz/points/${quizId}/${levelId}`, totalPoints);
        return response.data.data;
    } catch (e) {
        console.log(e);
    }
};
