import React, { useState } from 'react';
import './css/style.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DEFAULT_AVATAR_SRC } from '../utils/constants';
import { useSession } from 'providers/SessionProvider';
import { unsubscribe } from 'services/vas-auth-api';
import Notification from './notification/Notification';

const Profile = () => {
    const navigate = useNavigate();
    const { user: profile } = useSession();
    const [responseMessage, setResponseMessage] = useState({ message: "", isSuccess: true });

    const onLogout = () => {
        sessionStorage.clear();
        navigate("/register");
    }

    async function handleUnsubscribe() {
        try {
            setResponseMessage(res => ({ ...res, message: "" }));
            await unsubscribe();
            onLogout();
        } catch (ex) {
            console.log(ex);
            setResponseMessage({ message: ex.message, isSuccess: false });
        }
    }

    const { t } = useTranslation();

    return (
        <div className='home'>
            <div className="main-div">
                <div className="container box-wrapper">
                    <div className="header">
                        <div className="row align-items-center">
                            <div className="col-6 text-start">
                                <div className="logo d-flex">
                                    <a href="/">
                                        <img src="/image/Logo_new.png" alt="Logo" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="home-profile">
                        <div className="row gx-0">
                            <div className="col-7 text-start">
                                <div className="d-flex align-items-center">
                                    <div className="avatar">
                                        <img src={profile?.image || DEFAULT_AVATAR_SRC} alt="Avatar" className="Avatar" />
                                    </div>
                                    <div className="flex-grow-1 ms-2">
                                        <div className="profile-info">
                                            <h4 className="profile-name">{profile?.name} </h4>
                                            <p> {profile?.mobileNumber}</p>

                                        </div>
                                        {/* <div className="home-profile-status">Guest</div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-5 text-end">
                                <div className="h-100 d-flex align-items-center justify-content-end">
                                    <span className="profile-subscriber"> {t('7')} </span>
                                </div>
                            </div>

                            <div className="col-12 text-start">
                                <p className="profile-unsubscribe">{t('8')}</p>
                            </div>
                        </div>
                    </div>

                    <div className="content-box">

                        <ul className="profile-list-wrapper">
                            <li className="profile-list">
                                <NavLink to="/history" className="d-flex w-100">
                                    <div className="profile-list-pict">
                                        <img src="/image/controller.png" alt="History" className="game-icon" />
                                    </div>
                                    <div className="profile-list-info">
                                        <h4 className="profile-list-info-title">{t('9')}</h4>
                                    </div>
                                </NavLink>
                            </li>
                            <li className="profile-list">
                                <NavLink to="/edit" className="d-flex w-100">
                                    <div className="profile-list-pict">
                                        <img src="/image/Setting_group.png" alt="Edit Profile" />
                                    </div>
                                    <div className="profile-list-info">
                                        <h4 className="profile-list-info-title">{t('10')}</h4>
                                    </div>
                                </NavLink>
                            </li>

                            {/* <li className="profile-list">
                    <NavLink to="/language" className="d-flex w-100">
                        <div className="profile-list-pict">
                            <img src="/image/language.png" alt="Languages" /> 
                        </div>
                        <div className="profile-list-info">
                            <h4 className="profile-list-info-title">{t('11')}</h4>
                        </div>
                    </NavLink>
                </li> */}
                            <li className="profile-list">
                                <NavLink to="/policy" className="d-flex w-100">
                                    <div className="profile-list-pict">
                                        <img src="/image/note.png" alt="note" />
                                    </div>
                                    <div className="profile-list-info">
                                        <h4 className="profile-list-info-title">{t('12')}</h4>
                                    </div>
                                </NavLink>
                            </li>
                            <li className="profile-list">
                                <NavLink to="/contact-us" className="d-flex w-100">
                                    <div className="profile-list-pict">
                                        <img src="/image/support.png" alt="support" />
                                    </div>
                                    <div className="profile-list-info">
                                        <h4 className="profile-list-info-title">{t('13')}</h4>
                                    </div>
                                </NavLink>
                            </li>
                            <li className="profile-list">
                                <NavLink to="#" className="d-flex w-100" onClick={() => handleUnsubscribe()}>
                                    <div className="profile-list-pict">
                                        <img src="/image/logout.png" alt="logout" />
                                    </div>
                                    <div className="profile-list-info">
                                        <h4 className="profile-list-info-title">{t('71')}</h4>
                                    </div>
                                </NavLink>
                            </li>
                            <li className="profile-list">
                                <button type="button" className="d-flex w-100 menu-item-button" onClick={() => onLogout()}>
                                    <div className="profile-list-pict">
                                        <img src="/image/logout.png" alt="logout" />
                                    </div>
                                    <div className="profile-list-info">
                                        <h4 className="profile-list-info-title">{t('14')}</h4>
                                    </div>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <Notification message={responseMessage.message} isSuccess={responseMessage.isSuccess} />
        </div>
    )
}

export default Profile