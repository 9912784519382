export const routes = {
  home: "/",
  register: "/register",
  otp: "/otp",
  levels: "/levels",
  moreGames: "/more-games",
  gameDetails: "/game-details",
  leaderboard: "/leaderboard",
  findingOpponent: "/finding-opponent",
  championship: "/championship",
  playChampionship: "/championship/play",
  championshipDetail: (id) => `/championship/detail/${id}`,
  championshipResult: (id) => `/championship/result/${id}`,
  profile: "/profile",
  playGames: "/play",
  lostGames: "/loss",
  history: "/history",
  edit: "/edit",
  policy: "/policy",
  modal: "/modal",
  wonGames: "/won",
  language: "/language",
  contactUs: "/contact-us"
};
